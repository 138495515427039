import Aperitive from "../../assets/Poze/Aperitive.jpg";
import Carne from "../../assets/Poze/Carne.jpg";
import Desert from "../../assets/Poze/Desert.jpg";
import Important from "../../assets/Poze/Important.jpg";
import Meniuri from "../../assets/Poze/Meniuri.jpg";
import Paste from "../../assets/Poze/Paste.jpg";
import Burgeri from "../../assets/Poze/Burgeri.jpg"
import Pizza from "../../assets/Poze/Pizza.jpg";
import Pizza2 from "../../assets/Poze/Pizza2.jpg"
import Racoritoare from "../../assets/Poze/Racoritoare.jpg";
import Vinuri from "../../assets/Poze/Vinuri.jpg";
import { v4 as uuidv4 } from "uuid";
const images = [
  {
    id: uuidv4(),
    img: Aperitive,
  },
  {
    id: uuidv4(),
    img: Pizza,
  },
  {
    id: uuidv4(),
    img: Pizza2,
  },
  {
    id: uuidv4(),
    img: Paste,
  },
  {
    id: uuidv4(),
    img: Carne,
  },
  {
    id: uuidv4(),
    img: Burgeri,
  },
  {
    id: uuidv4(),
    img: Meniuri,
  },
  {
    id: uuidv4(),
    img: Desert,
  },
  {
    id: uuidv4(),
    img: Racoritoare,
  },
  {
    id: uuidv4(),
    img: Vinuri,
  },
  {
    id: uuidv4(),
    img: Important,
  },
];
export default images;
